import { Observable } from 'rxjs';
import {
  ReportGetProductQueryParamsDto,
  ResponseReportDto,
} from '../dtos/report.dto';

export abstract class ReportProductRepository {
  abstract getProduct(
    params: ReportGetProductQueryParamsDto
  ): Observable<ResponseReportDto>;
}
