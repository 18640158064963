import { Observable } from 'rxjs';
import {
  ReportGetRequestQueryParamsDto,
  ResponseReportDto,
} from '../dtos/report.dto';

export abstract class ReportRepository {
  abstract getRequest(
    params: ReportGetRequestQueryParamsDto
  ): Observable<ResponseReportDto>;
}
